.custom-pie-chart .recharts-default-legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.custom-pie-chart .recharts-legend-item {
    margin-right: 0;
    text-align: justify;
}

.ReactModal__Overlay {
    height: 100vh;
    display: flex;
}

.ReactModal__Content {
    position: relative !important;
}

/* CSS Reset for lists since tailwinds doesn't do this */
ul, li {
    list-style: none;
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

dt {
    display: inline-block;
}

dd {
    display: inline;
}
